import React from "react";
import { useNavigate } from "react-router-dom";
import { House, Phone, WhatsApp, Email } from "@mui/icons-material";
import { Button } from "@mui/material";
import { SERVICE_OFFERINGS } from "../Data";

import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();

  const quickLinks = [
    { id: 1, text: "Home", route: "/" },
    { id: 2, text: "Services", route: "/services" },
    { id: 3, text: "About Us", route: "/about-us" },
    { id: 4, text: "Client ", route: "/client" },
    { id: 5, text: "Contact Us", route: "/contact" },
  ];

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-container-1">
            <div className="footer-logo">
              <img src="/TechBRJ-Single-Color-Logo.png" alt="TechBRJ" />
            </div>
            <p className="footer-info">
              Tech BRJ is a dynamic and innovative technology company
              specializing in comprehensive digital solutions. We offer a wide
              range of services including web development, custom software
              development, Electron app development, and WhatsApp integration
              services. Our team of experienced developers and designers is
              dedicated to creating tailored, high-performance solutions that
              meet the unique needs of businesses across industries.
            </p>
          </div>

          <div className="footer-container-2">
            <p className="container-head">Quick links</p>
            <div className="container-links">
              {quickLinks.map((link) => (
                <Button
                  key={link.id}
                  className="footer-links"
                  onClick={() => handleNavigate(link.route)}
                >
                  {link.text}
                </Button>
              ))}
            </div>
          </div>

          <div className="footer-container-3">
            <p className="container-head">Services we Provide</p>
            <div className="industries-wrapper">
              <div className="industries-links-container">
                {SERVICE_OFFERINGS.map((link, index) => (
                  <div key={index} className="industry-link">
                    <p className="footer-links">{link.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="footer-container-4">
            <p className="container-head">Address</p>
            <div className="address-row">
              <div className="address-icon">
                <House />
              </div>
              <p>A-56, Talkatora Industrial Estate, Lucknow, UP, India.</p>
            </div>
            <div className="address-row">
              <div className="address-icon">
                <Phone />
              </div>
              <p>9236599565</p>
            </div>
            <div className="address-row">
              <div className="address-icon">
                <WhatsApp />
              </div>
              <p>+91 92356599565</p>
            </div>
            <div className="address-row">
              <div className="address-icon">
                <Email />
              </div>
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="mailto:karan@techbrj.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>karan@techbrj.com</p>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
