import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <>
      <div className="loader-container">
        <div className="loader-image">
          <img src="/TechBRJ-Single-Color-Logo.png" alt="logo" />
        </div>
      </div>
    </>
  );
};

export default Loader;
