export const offerings = [
  {
    imgSrc: "/Services/online-store.png",
    imgAlt: "Smart Retail Solution",
    heading: "Website Development",
    description:
      "Looking for professional website development? We create responsive websites tailored to your business. Whether it’s a portfolio site, ecommerce platform, or a custom solution, our websites are designed for all.",
  },
  {
    imgSrc: "/Services/smartphone.png",
    imgAlt: "Visual Merchandise",
    heading: "Mobile App Development",
    description:
      "Need a custom mobile app for your business? We build high-performance apps for Android and iOS, focusing on user-friendly design and seamless functionality.",
  },
  {
    imgSrc: "/Services/whatsapp.png",
    imgAlt: "Innovation Centre",
    heading: "WhatsApp Marketing",
    description:
      "Boost your customer outreach with WhatsApp Marketing. Use bulk messaging and automated campaigns to connect directly with your audience, all through a trusted platform.",
  },
  {
    imgSrc: "/Services/software-development.png",
    imgAlt: "Software & Content",
    heading: "Custom Software Development",
    description:
      "Transform your business with a custom CRM system designed to streamline sales, manage leads, and automate daily tasks. Our solutions are built to enhance efficiency and improve customer relationships.",
  },
];

export const solutions = [
  {
    icon: "/Services/online-store.png",
    title: "Website Development (Portfolio, E-commerce, Custom Websites)",
    description:
      "Looking for professional website development services? We specialize in creating responsive, SEO-optimized websites that enhance user experience and drive engagement. Whether you need a portfolio website to showcase your work, an e-commerce platform to sell products securely, or a custom website tailored to your business needs, we've got you covered.",
    services: [
      {
        heading: "Portfolio Websites",
        description: "Show off your work with stunning, fast-loading websites.",
      },
      {
        heading: "E-commerce Websites",
        description:
          "Fully integrated online stores with secure payment systems and easy product management.",
      },
      {
        heading: "Custom Websites",
        description:
          "Unique designs and functionality tailored to your specific business requirements.",
      },
    ],
    ld: "Our team of expert developers ensures your website is not only visually appealing but also fast, mobile-friendly, and optimized for search engines, giving you a competitive edge online.",
  },
  {
    icon: "/Services/smartphone.png",
    title: "Mobile App Development",
    description:
      "In today's mobile-first world, having a custom mobile app can significantly boost customer engagement and streamline business operations. We offer mobile app development services for both Android and iOS platforms, ensuring your app delivers high performance, a seamless user interface, and a top-notch user experience.",
    services: [
      {
        heading: "Custom App Development",
        description:
          "Tailored mobile applications for businesses across various industries.",
      },
      {
        heading: "Cross-Platform Apps",
        description: "Develop once and deploy on both Android and iOS.",
      },
      {
        heading: "User-Centric Design",
        description: "A focus on intuitive UI/UX that keeps users engaged.",
      },
    ],
    ld: "With our comprehensive app development services, we ensure your business stays connected with your customers anytime, anywhere.",
  },
  {
    icon: "/Services/whatsapp.png",
    title: "WhatsApp Marketing",
    description:
      "Supercharge your marketing campaigns with our WhatsApp Marketing services. Reach your customers directly on their phones with targeted, personalized messages. Whether it's bulk messaging, automated campaigns, we help you engage customers effectively.",
    services: [
      {
        heading: "Bulk Messaging",
        description:
          "Send promotional messages, updates, and offers to a wide audience.",
      },
      {
        heading: "Personalized Campaigns",
        description:
          "Tailor messages based on customer behavior and preferences.",
      },
    ],
    ld: "With our WhatsApp Marketing solutions, you can increase your brand visibility, boost engagement, and drive conversions in a quick and cost-effective manner.",
  },
  {
    icon: "/Services/crm.png",
    title: "Custom CRM Development",
    description:
      "Take control of your business processes with a custom CRM solution designed specifically for your needs. Our Custom CRM development services enable you to manage leads, streamline sales pipelines, automate workflows, and improve customer relationships.",
    services: [
      {
        heading: "Lead Management",
        description:
          "Track, nurture, and convert leads into customers with ease.",
      },
      {
        heading: "Sales Automation",
        description: "Automate repetitive tasks and improve sales efficiency.",
      },
      {
        heading: "Custom Dashboards",
        description: "Get real-time insights into your business operations.",
      },
    ],
    ld: "A well-designed CRM system can help you enhance customer satisfaction, improve operational efficiency, and boost revenue by providing actionable insights into your business.",
  },
  {
    icon: "/Services/bill.png",
    title: "Facial Attendance System",
    description:
      "Modernize your business with our Facial Attendance System. Using advanced facial recognition technology, our system ensures quick, contactless, and accurate employee attendance tracking. Perfect for businesses looking for high-security solutions and automated attendance management.",
    services: [
      {
        heading: "Real-Time Recognition",
        description:
          "Instant, accurate facial recognition for employee attendance.",
      },
      {
        heading: "Cloud-Based Storage",
        description: "Securely store and access attendance data in real-time.",
      },
      {
        heading: "Detailed Reporting",
        description: "Generate attendance reports on the go.",
      },
    ],
    ld: "By adopting our Facial Attendance System, you can eliminate manual errors, improve attendance accuracy, and enhance workplace security.",
  },
  {
    icon: "/Services/bill.png",
    title: "POS-Based Billing Software",
    description:
      "Streamline your sales and inventory management with our POS-based Billing Software. Designed for retail stores, restaurants, and service-based businesses, our point-of-sale software allows you to process transactions efficiently, review inventory, and generate real-time sales reports.",
    services: [
      {
        heading: "Inventory Management",
        description: "Track stock levels, sales trends.",
      },
      {
        heading: "Real-Time Sales Reporting",
        description: "Get instant insights into your daily sales performance.",
      },
      {
        heading: "Multi-Location Support",
        description: "Manage multiple stores from a single platform.",
      },
    ],
    ld: "With our POS Billing Software, you can reduce checkout times, prevent stockouts, and make data-driven decisions to boost your bottom line.",
  },
  {
    icon: "/Services/software-development.png",
    title: "Custom Software Development",
    description:
      "Every business is unique, and so are its software needs. Our Custom Software Development services are designed to build solutions that align with your business goals. From automating workflows to developing industry-specific applications, we provide scalable and secure solutions.",
    services: [
      {
        heading: "Business Automation",
        description:
          "Streamline operations with tailored software that improves efficiency.",
      },
      {
        heading: "API Integrations",
        description:
          "Seamless integration with third-party tools and services.",
      },
      {
        heading: "Custom Features",
        description: "Build features and modules based on your specific needs.",
      },
    ],
    ld: "Our expertise in custom software development ensures that your solution is flexible, scalable, and future-proof, helping you stay competitive in a rapidly changing market.",
  },
];

export const SERVICE_OFFERINGS = [
  {
    src: "/Services/online-store.png",
    alt: "Web-Development",
    text: "Web Development",
  },
  {
    src: "/Services/smartphone.png",
    alt: "Mobile App Development",
    text: "Mobile App Development",
  },
  {
    src: "/Services/whatsapp.png",
    alt: "WhatsApp Marketing",
    text: "WhatsApp Marketing",
  },
  {
    src: "/Services/crm.png",
    alt: "Custom CRM Development",
    text: "Custom CRM Development",
  },
  {
    src: "/Services/bill.png",
    alt: "Facial Attendance System",
    text: "Facial Attendance System",
  },
  {
    src: "/Services/bill.png",
    alt: "POS-Based Billing Software",
    text: "POS-Based Billing Software",
  },
  {
    src: "/Services/software-development.png",
    alt: "Custom Software Development",
    text: "Custom Software Development",
  },
];
