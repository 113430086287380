import React, { lazy, Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./App.css";

import Header from "./Util/Header/Header";
import Loader from "./Util/Loader/Loader";
import Footer from "./Util/Footer/Footer";

const Home = lazy(() => import("./Home/Home"));

const Services = lazy(() => import("./Services/Services"));

const About = lazy(() => import("./AboutUs/About"));

const Contact = lazy(() => import("./ContactUs/Contact"));

const Client = lazy(() => import("./Clients/Client"));

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(242, 116, 32)",
    },
    secondary: {
      main: "#021a47",
    },
  },
  typography: {
    fontFamily: "Titillium Web",
  },
});

const SuspenseLayout = () => (
  <>
    <Header />
    <Suspense
      fallback={
        <div className="loader-container">
          <Loader />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  </>
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route element={<SuspenseLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/client" element={<Client />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}
export default App;
