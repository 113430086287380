import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.css";

const Header = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const PageRoutes = [
    { id: 1, routeName: "Home", pageUrl: "/" },
    { id: 2, routeName: "Services", pageUrl: "/services" },
    { id: 3, routeName: "About Us", pageUrl: "/about-us" },
    { id: 4, routeName: "Client", pageUrl: "/client" },
    { id: 5, routeName: "Contact Us", pageUrl: "/contact" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname]);

  const navigateTo = (route) => {
    navigate(route);
    setDrawerOpen(false);
  };

  const toggleDrawer = (toggle) => {
    setDrawerOpen(toggle);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={`app-bar ${isScrolled ? "scrolled" : ""}`}
      >
        <Toolbar className="header-toolbar">
          <div className="header-logo-container">
            <img
              src="/TechBRJ-Single-Color-Logo.png"
              alt="logo"
              onClick={() => navigateTo("/")}
            />
          </div>

          <div className="header-menu-bar">
            <MenuIcon fontSize="large" onClick={() => toggleDrawer(true)} />
          </div>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
          >
            <div className="drawer-wrapper">
              <div className="drawer-content">
                <img src="/TechBRJ-Single-Color-Logo.png" alt="logo" />
                {PageRoutes.map((pr) => (
                  <Button
                    key={pr.id}
                    color="primary"
                    className={`btn ${
                      location.pathname === pr.pageUrl ? "active" : ""
                    }`}
                    onClick={() => navigateTo(pr.pageUrl)}
                    style={{ fontSize: "1.1rem" }}
                  >
                    {pr.routeName}
                  </Button>
                ))}
              </div>
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
